<template>
    <div class="delivery-area">
        <div class="search">
            <div class="form">
                <div class="row">
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>ภูมิภาค</label>
                            <dropdown
                                v-model="form.region"
                                placeholder="ทั้งหมด"
                                :classes="{ input: [ 'square', 'input-option' ] }"
                                :options="option.region"
                                @change="changeRegion($event)">
                            </dropdown>
                        </div>
                    </div>
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>จังหวัด</label>
                            <dropdown
                                v-model="form.province"
                                placeholder="ทั้งหมด"
                                :classes="{ input: [ 'square', 'input-option' ] }"
                                :options="option.province"
                                @change="changeProvince($event)">
                            </dropdown>
                        </div>
                    </div>
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>อำเภอ/เขต</label>
                            <dropdown
                                v-model="form.district"
                                placeholder="ทั้งหมด"
                                :classes="{ input: [ 'square', 'input-option' ] }"
                                :options="option.district"
                                @change="changeDistrict(form.province, $event)">
                            </dropdown>
                        </div>
                    </div>
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>ตำบล/แขวง</label>
                            <dropdown
                                v-model="form.subDistrict"
                                placeholder="ทั้งหมด"
                                :classes="{ input: [ 'square', 'input-option' ] }"
                                :options="option.subDistrict">
                            </dropdown>
                        </div>
                    </div>
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>ไปรษณีย์</label>
                            <input
                                v-model="form.postCode"
                                type="text"
                                placeholder="ทั้งหมด"
                                class="square" />
                        </div>
                    </div>
                    <div class="col col-6 col-md-4">
                        <div class="input-field">
                            <label>โซนพื้นที่</label>
                            <dropdown
                                v-model="form.deliveryZone"
                                placeholder="ทั้งหมด"
                                :noTyping="true"
                                :classes="{ input: [ 'square', 'input-option' ] }"
                                :options="option.deliveryZone">
                            </dropdown>
                        </div>
                    </div>
                </div>

                <div class="remark mt-1"><b-icon icon="info-circle-fill"></b-icon> กดเลือกพื้นที่ทั้งหมด หรือบางส่วนแล้วกดปุ่ม ต้นหา</div>

                <div class="mt-3 text-center">
                    <button class="btn btn-main btn-search" @click="submitSearch()"><font-awesome-icon :icon="['fas', 'search']" class="icon" /> ค้นหา</button>
                    <button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
                </div>
            </div>
        </div>

        <div class="summary" v-if="!isResultReady && summary">
            <h4>พื้นทื่จัดส่งที่ร้านค้าได้ตั้งค่าไว้</h4>
            <div class="date">พื้นที่จัดส่ง: {{ summary.lastUpdate ? displayDate(summary.lastUpdate) : 'ยังไม่ได้ตั้งค่า' }}</div>

            <div class="wrapper">
                <div class="item" v-for="(value, key, index) in summary.regions" :key="index" @click="selectRegion(key)">
                    <h5>{{ key }}</h5>
                    <div class="panel-board">
                        <div class="circle zone1">
                            <div>
                                <span>{{ value.zone1 }}</span>
                                <label>โซนที่ 1</label>
                            </div>
                        </div>
                        <div class="circle zone2">
                            <div>
                                <span>{{ value.zone2 }}</span>
                                <label>โซนที่ 2</label>
                            </div>
                        </div>
                        <div class="circle zone3">
                            <div>
                                <span>{{ value.zone3 }}</span>
                                <label>โซนที่ 3</label>
                            </div>
                        </div>
                        <div class="circle zone4">
                            <div>
                                <span>{{ value.zone4 }}</span>
                                <label>โซนที่ 4</label>
                            </div>
                        </div>
                        <div class="circle">
                            <div>
                                <span>{{ value.notSet }}</span>
                                <label>ยังไม่ได้กำหนด</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="isResultReady">
            <div class="operation">
                <span>ตั้งค่าพื้นที่ที่เลือกไว้ (<span class="text-danger">{{ resultList.filter(x => x.isSelect).length }}</span> รายการ)</span>
                <select v-model="optionArea">
                    <option value="0">ไม่จัดส่ง</option>
                    <option value="1">จัดส่งโซน 1</option>
                    <option value="2">จัดส่งโซน 2</option>
                    <option value="3">จัดส่งโซน 3</option>
                    <option value="4">จัดส่งโซน 4</option>
                </select>
                <button class="btn btn-sm btn-warning" @click="assignAreaRate()">ตั้งค่า</button>
            </div>

            <div class="remark mt-2 mb-2"><b-icon icon="info-circle-fill"></b-icon> กดเลือกพื้นที่ทั้งหมด หรือบางส่วน แล้วเลือกกลุ่มด้านบน เพื่อจัดกลุ่มค่าส่งที่ต่างกัน 3 กลุ่ม</div>

            <div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                <table>
                    <thead>
                        <tr>
                            <th class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!isSelectAll"
                                        @click="toggleAllSelection(true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="isSelectAll"
                                        @click="toggleAllSelection(false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </th>
                            <th>ภูมิภาค</th>
                            <th>จังหวัด</th>
                            <th>เขต/อำเภอ</th>
                            <th>แขวง/ตำบล</th>
                            <th>รหัสไปรษณีย์</th>
                            <th>โซนพื้นที่</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in resultList" :key="index">
                            <td class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!item.isSelect"
                                    @click="toggleSelection(item, true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="item.isSelect"
                                    @click="toggleSelection(item, false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </td>
                            <td>{{ item.region }}</td>
                            <td>{{ item.province }}</td>
                            <td>{{ item.district }}</td>
                            <td>{{ item.subDistrict }}</td>
                            <td>{{ item.postCode }}</td>
                            <td class="rate" :class="getStyleRate(item.rate)">{{ item.rateText }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import Helper from '@/services/helper';
import OptionDeliveryZone from '@/services/staticOption/deliveryZone';
import MasterService from '@/services/masterService';
import SellerService from '@/services/sellerService';
import MixinDragScroll from '@/mixins/dragScroll';
import MixinSettingProduct from '@/components/seller/mixins/settingProduct';

export default {
    mixins: [ MixinDragScroll, MixinSettingProduct ],
	data() {
		return {
            optionArea: '',
			form: this.initForm(),
			option: {
                region: [],
                province: [],
                district: [],
                subDistrict: [],
                deliveryZone: OptionDeliveryZone.option
            },
            summary: null
		}
	},
	async mounted() {
		this.initOptions();
        this.getDeliveryAreaSummary();
	},
	methods: {
		async initOptions() {
            const [resultRegion, resultProvince] = await Promise.all([
                MasterService.getRegion(),
                MasterService.getProvince('')
            ]);

			this.option.region = Helper.setupOption(resultRegion.data);
            this.option.province = Helper.setupOption(resultProvince.data);
		},
		initForm() {
			return {
				region: '',
				province: '',
				district: '',
                subDistrict: '',
                postCode: '',
                deliveryZone: ''
			};
		},
        displayDate(text) {
            const date = new Date(text);

            return moment(date).format('DD/MM/YYYY HH:mm');
        },
        async getDeliveryAreaSummary() {
            let loader = this.$modalLoader.render();
            const result = await SellerService.getDeliveryAreaSummary();
            loader.hide();

            if (result.data) {
                this.summary = result.data;
            }
        },
        selectRegion(region) {
            this.form.region = region;

            this.submitSearch();
        },
        async changeRegion(region) {
            if (region) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getProvince(region);
                loader.hide();

                this.option.district = [];
                this.option.subDistrict = [];

				this.option.province = Helper.setupOption(result.data);
            } else {
                this.option.province = [];
                this.option.district = [];
                this.option.subDistrict = [];
            }

            this.form.province = '';
            this.form.district = '';
            this.form.subDistrict = '';
        },
		async changeProvince(province) {
            if (province) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getDistrict(province);
                loader.hide();

                this.option.subDistrict = [];

				this.option.district = Helper.setupOption(result.data);
            } else {
                this.option.district = [];
                this.option.subDistrict = [];
            }

            this.form.district = '';
            this.form.subDistrict = '';
        },
        async changeDistrict(province, district) {
            if (province && district) {
                let loader = this.$modalLoader.render();
                const result = await MasterService.getSubDistrict(province, district);
                loader.hide();

				this.option.subDistrict = Helper.setupOption(result.data);
            } else {
                this.option.subDistrict = [];
            }

            this.form.subDistrict = '';
        },
		async submitSearch() {
            this.isSelectAll = false;
            
			let loader = this.$modalLoader.render();
            const result = await SellerService.getDeliveryAreaRate(this.form);
            loader.hide();

            this.isResultReady = true;
            this.resultList = result.data.map(x => ({ ...x, isSelect: false }));
		},
        resetForm() {
            this.isResultReady = false;
			this.form = this.initForm();
		},
        getStyleRate(rate) {
            let style = '';

            if (rate) {
                style = 'text-success';
            }

            return style;
        },
        async assignAreaRate() {
            if (this.optionArea) {
                const param = {
                    rate: this.optionArea,
                    assignedAreas: this.getSelectedAreas()
                };
                let loader = this.$modalLoader.render();
                await SellerService.assignAreaRate(param);
                loader.hide();

                // Reload
                this.submitSearch();
            } else {
                this.$swal(Helper.warningAlert('ยังไมได่้เลือกตัวเลือก', 'โปรดเลือกกลุ่มสำหรับตั้งค่าพื้นที่จัดส่ง'));
            }
        },
        getSelectedAreas() {
            return this.resultList
                .filter(item => item.isSelect)
                .map(item => item.id);
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
@import '@/components/seller/style/Setting.scss';

.summary {
    margin-top: 30px;
    text-align: center;
    background: #f5f5f5;
    padding: 40px;

    h4 {
        font-weight: bold;
        font-size: 16px;
        color: #333;
        margin: 0;
    }

    .date {
        font-size: 12px;
        color: #777;
        margin: 5px 0 20px;
    }

    .wrapper {
        max-width: 800px;
        margin: auto;

        .item {
            position: relative;
            background: #fff;
            color: #333;
            margin: 40px 0;
            border-top: 2px solid #d3e0e5;
            cursor: pointer;

            h5 {
                font-size: 13px;
                font-weight: bold;
                position: absolute;
                top: -23px;
                left: 0;
                background: #6c757d;
                color: #fff;
                padding: 3px 10px;
            }

            &:hover {
                opacity: 0.7;
            }

            .panel-board {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .circle {
                    width: 110px;
                    height: 110px;
                    border: 3px solid #999;
                    margin: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include borderRadius(50%);

                    &.zone1 {
                        border-color: #f7a6a6;
                    }

                    &.zone2 {
                        border-color: #4ca78b;
                    }

                    &.zone3 {
                        border-color: #fdc20a;
                    }

                    &.zone4 {
                        border-color: #a2cde1;
                    }

                    span {
                        display: block;
                        color: #333;
                        font-size: 24px;
                        line-height: 26px;
                    }

                    label {
                        display: block;
                        color: #888;
                        margin-top: 4px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
</style>