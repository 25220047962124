<template>
	<div class="setting">
		<h2>
			<span :class="{ 'active': currentMenu === 'installation' }" @click="changeMenu('installation')"><b-icon icon="gear" class="icon"></b-icon> ตั้งค่าการติดตั้งสินค้า</span>
			<span class="line">|</span>
			<span :class="{ 'active': currentMenu === 'delivery' }" @click="changeMenu('delivery')"><b-icon icon="truck" class="icon"></b-icon> ตั้งค่าการจัดส่งสินค้า</span>
		</h2>

		<template v-if="currentMenu === 'installation'">
			<!-- <div class="delivery-note" v-if="!isAllowInstallation">
				หากทางร้านค้ามีความประสงค์จะเปิดขายยางแบบติดตั้ง โปรดติดต่อ Yellowtire ที่ LINE: <a href="http://line.me/ti/p/~yellowtire">yellowtire</a>  หรือ <a href="mailto:info@yellowtire.com">info@yellowtire.com</a>
			</div>

			<div class="toggle-form">
				<div class="blocker" v-if="!isAllowInstallation"></div>

				<div class="input-field">
					<switch-button
						v-model="hasInstallation"
						@toggle="saveInstallationInfo($event)">
						ร้านค้ามีบริการติดตั้งหรือไม่
					</switch-button>
				</div>
			</div>

			<div class="setting-panel">
				<div class="blocker" v-if="!hasInstallation"></div>

				<div class="tabs">
					<button @click="changeTab(4)" :class="{ 'active': tab === 4 }">กำหนดจำนวนวันเพื่อจัดเตรียม</button>
					<button @click="changeTab(5)" :class="{ 'active': tab === 5 }">กำหนดจำนวนขั้นต่ำสำหรับติดตั้ง</button>
				</div>

				<seller-installation-product v-if="tab === 4"></seller-installation-product>
				<seller-installation-min-qty v-if="tab === 5"></seller-installation-min-qty>
			</div> -->

			<div class="tabs">
				<button @click="changeTab(4)" :class="{ 'active': tab === 4 }">กำหนดจำนวนวันเพื่อจัดเตรียม</button>
				<button @click="changeTab(5)" :class="{ 'active': tab === 5 }">กำหนดจำนวนขั้นต่ำสำหรับติดตั้ง</button>
			</div>

			<seller-installation-product v-if="tab === 4"></seller-installation-product>
			<seller-installation-min-qty v-if="tab === 5"></seller-installation-min-qty>
		</template>
		<template v-if="currentMenu === 'delivery'">
			<div class="delivery-note" v-if="!isAllowDelivery">
				หากทางร้านค้ามีความประสงค์จะเปิดขายยางแบบจัดส่ง โปรดติดต่อ Yellowtire ที่ LINE: <a href="http://line.me/ti/p/~yellowtire">yellowtire</a>  หรือ <a href="mailto:info@yellowtire.com">info@yellowtire.com</a>
			</div>

			<div class="toggle-form setting-panel">
				<div class="blocker" v-if="!isAllowDelivery"></div>

				<div class="input-field">
					<switch-button
						v-model="hasDelivery"
						@toggle="saveDeliveryInfo($event)">
						ร้านค้ามีบริการจัดส่งหรือไม่
					</switch-button>
				</div>
			</div>

			<div class="setting-panel">
				<div class="blocker" v-if="!hasDelivery"></div>

				<div class="tabs">
					<button @click="changeTab(1)" :class="{ 'active': tab === 1 }">STEP 1: กำหนดโซนพื้นที่จัดส่ง</button>
					<button @click="changeTab(2)" :class="{ 'active': tab === 2 }">STEP 2: กำหนดค่าจัดส่งตามโซน</button>
					<button @click="changeTab(3)" :class="{ 'active': tab === 3 }">กำหนดจำนวนขั้นต่ำสำหรับจัดส่ง</button>
				</div>

				<seller-delivery-area v-if="tab === 1"></seller-delivery-area>
				<seller-delivery-product v-if="tab === 2"></seller-delivery-product>
				<seller-delivery-min-qty v-if="tab === 3"></seller-delivery-min-qty>
			</div>
		</template>
	</div>
</template>

<script>
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import SellerDeliveryArea from '@/components/seller/shop/DeliveryArea';
import SellerDeliveryProduct from '@/components/seller/shop/DeliveryProduct';
import SellerDeliveryMinQty from '@/components/seller/shop/DeliveryMinQty';
import SellerInstallationProduct from '@/components/seller/shop/InstallationProduct';
import SellerInstallationMinQty from '@/components/seller/shop/InstallationMinQty';

export default {
	components: {
        SellerDeliveryArea,
		SellerDeliveryProduct,
		SellerDeliveryMinQty,
		SellerInstallationProduct,
		SellerInstallationMinQty
    },
	data() {
		return {
			currentMenu: 'installation',
			isAllowInstallation: false,
			isAllowDelivery: false,
			hasInstallation: false,
			hasDelivery: false,
			tab: 4
		}
	},
	mounted() {
        this.loadSettingInfo();
    },
	methods: {
		changeMenu(menu) {
			this.currentMenu = menu;

			switch (menu) {
				case 'installation':
					this.tab = 4;
					break;

				case 'delivery':
					this.tab = 1;
					break;
			}
		},
		changeTab(tab) {
			this.tab = tab;
		},
		async loadSettingInfo() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.getSettingInfo();

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			} else if (result.data) {
				this.load = result.data;
				this.isAllowInstallation = this.load ? this.load.allowInstallation : false;
				this.isAllowDelivery = this.load ? this.load.allowDelivery : false;
				this.hasDelivery = this.load ? this.load.hasDelivery : false;
				this.hasInstallation = this.load ? this.load.hasInstallation : false;
			}

			loader.hide();
		},
		async saveInstallationInfo(value) {
			const param = {
				hasInstallation: value
			};
			let loader = this.$modalLoader.render();
			await SellerService.saveInstallationInfo(param);
			loader.hide();
		},
		async saveDeliveryInfo(value) {
			const param = {
				hasDelivery: value
			};
			let loader = this.$modalLoader.render();
			await SellerService.saveDeliveryInfo(param);
			loader.hide();
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.setting {
	h2 {
		font-size: 20px;
		font-weight: bold;
		color: #555;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px dashed #ccc;

		@media only screen and (max-width: $screenExtraSmall) {
			font-size: 14px;
		}

		span {
			cursor: pointer;
			opacity: 0.5;
			font-family: 'Open Sans', 'Kanit';

			&:hover {
				border-bottom: 1px solid #555;
			}

			&.active {
				opacity: 1;
			}

			&.line {
				margin: 0 15px;
				font-weight: normal;
				opacity: 1;
				cursor: default;

				@media only screen and (max-width: $screenExtraSmall) {
					margin: 0 8px;
				}
			}

			.icon {
				margin-right: 5px;
			}
		}
	}

	.delivery-note {
		font-size: 13px;
		padding: 15px;
		border: 1px solid #b54242;
		color: #555;
		margin-bottom: 20px;
	}

	.setting-panel {
		position: relative;
		
		.blocker {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.7;
			background: #fff;
			z-index: 1;
		}
	}

	.toggle-form {
		margin-bottom: 20px;

		.delivery-data {
			position: relative;

			.blocker {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: #fff;
				opacity: 0.6;
				z-index: 1;
				cursor: not-allowed;
			}
		}

		.input-field {
			display: flex;
			align-items: flex-start;
			
			input {
				max-width: 150px;
				width: 100%;
			}

			.text {
				margin-left: 20px;
				flex: 1;
			}
		}
	}

	.tabs {
		margin-top: 30px;
		display: flex;
		border-bottom: 1px solid #aaa;

		button {
			border: none;
			background: none;
			padding: 7px 12px;
			color: #999;
			font-size: 13px;

			@media only screen and (max-width: $screenExtraSmall) {
				padding: 7px 5px;
				font-size: 12px;
			}

			&:hover {
				opacity: 1;
			}

			&.active {
				color: #333;
				border: 1px solid #aaa;
				border-bottom: none;
				@include borderRadius(7px 7px 0 0);
			}
		}
	}
}
</style>