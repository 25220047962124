const option = [
    { id: '1', name: 'มีสต๊อค'},
    { id: '2', name: 'สั่งจากร้านอื่น'},
    { id: '3', name: 'สั่งจากโรงงาน'},
    
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};