<template>
    <div>
        <seller-search-product-setting @submitSearch="submitSearch($event)"></seller-search-product-setting>

        <template v-if="isResultReady">
            <div class="operation">
                <span>สินค้าที่เลือกไว้ (<span class="text-danger">{{ resultList.filter(x => x.isSelect).length }}</span>)</span>

                <select v-model="stockType">
                    <option v-for="(item, index) in option.stockType" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>

                <select v-model="dayPrepareService">
                    <option value="">ใช้ค่าเริ่มต้น</option>
                    <option v-for="(item, index) in option.days" :key="index" :value="item.key">
                        {{ item.name }}
                    </option>
                </select>

                <button class="btn btn-sm btn-warning" @click="assignDayPrepareService()">ตั้งค่าจำนวนวันเพื่อจัดเตรียม</button>
            </div>

            <div class="remark mt-2 mb-2"><b-icon icon="info-circle-fill"></b-icon> กดเลือกยี่ห้อยาง หรือขอบล้อ หรือบางส่วน แล้วเลือกกลุ่มด้านบน เพื่อตั้งค่าจำนวนจัดเตรียมสินค้า</div>

            <div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                <table>
                    <thead>
                        <tr>
                            <th class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!isSelectAll"
                                        @click="toggleAllSelection(true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="isSelectAll"
                                        @click="toggleAllSelection(false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </th>
                            <th>ยี่ห้อ/รุ่นสินค้า</th>
                            <th>ขนาด</th>
                            <th>ปียาง</th>
                            <th class="text-center">
                                จำนวนวันเพื่อจัดเตรียมสินค้า
                                <br />
                                (มีสต๊อค)
                            </th>
                            <th class="text-center">
                                จำนวนวันเพื่อจัดเตรียมสินค้า
                                <br />
                                (สั่งจากร้านอื่น)
                            </th>
                            <th class="text-center">
                                จำนวนวันเพื่อจัดเตรียมสินค้า
                                <br />
                                (สั่งจากโรงงาน)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in resultList" :key="index">
                            <td class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!item.isSelect"
                                    @click="toggleSelection(item, true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="item.isSelect"
                                    @click="toggleSelection(item, false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </td>
                            <td>{{ item.brand }}<br />{{ item.model }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.year }}</td>
                            <td class="text-center">{{ item.dayPrepareService }} วัน</td>
                            <td class="text-center">{{ item.dayPrepareServiceSublet }} วัน</td>
                            <td class="text-center">{{ item.dayPrepareServiceFactory }} วัน</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import OptionStockType from '@/services/staticOption/stockType';
import MixinDragScroll from '@/mixins/dragScroll';
import MixinSettingProduct from '@/components/seller/mixins/settingProduct';
import SellerSearchProductSetting from '@/components/seller/shop/SearchProductSetting';

export default {
    mixins: [ MixinDragScroll, MixinSettingProduct ],
    components: {
        SellerSearchProductSetting
    },
	async mounted() {
        this.submitSearch();
	},
	data() {
		return {
            stockType: '1',
            dayPrepareService: '',
			option: {
                days: Helper.getOptionGapDay(),
                stockType: OptionStockType.option
            }
		}
	},
	methods: {
		async submitSearch(param) {
            this.isSelectAll = false;

			let loader = this.$modalLoader.render();
            const result = await SellerService.getProductForSetting(param);
            loader.hide();

            this.isResultReady = true;
            this.resultList = result.data.map(x => ({ ...x, isSelect: false }));
		},
        async assignDayPrepareService() {
            const param = {
                assignedProducts: this.getSelectedProducts(),
            };

            switch (this.stockType) {
                case '1':
                    param.dayPrepareService = this.dayPrepareService;
                    break;

                case '2':
                    param.dayPrepareServiceSublet = this.dayPrepareService;
                    break;

                case '3':
                    param.dayPrepareServiceFactory = this.dayPrepareService;
                    break;
            }

            let loader = this.$modalLoader.render();
            await SellerService.assignDayPrepareProduct(param);
            loader.hide();

            // Reload
            this.dayPrepareService = '';
            this.submitSearch();
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
@import '@/components/seller/style/Setting.scss';
</style>