const option = [
    { id: '99', name: 'ยังไม่ได้กำหนดโซนจัดส่ง'},
    { id: '1', name: 'จัดส่งโซน 1'},
    { id: '2', name: 'จัดส่งโซน 2'},
    { id: '3', name: 'จัดส่งโซน 3'},
    { id: '4', name: 'จัดส่งโซน 4'},
];

const method = {
    getValueByKey(id) {
        return option.find(x => x.id === id).name;
    }
}

export default {
    option: option,
    service: method
};