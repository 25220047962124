<template>
    <div>
        <seller-search-product-setting @submitSearch="submitSearch($event)"></seller-search-product-setting>

        <template v-if="isResultReady">
            <div class="operation">
                <span>สินค้าที่เลือกไว้ (<span class="text-danger">{{ resultList.filter(x => x.isSelect).length }}</span>)</span>

                <select v-model="optionRate">
                    <option value="0">ไม่จัดส่ง</option>
                    <option value="1">ตั้งราคาจัดส่งโซน 1</option>
                    <option value="2">ตั้งราคาจัดส่งโซน 2</option>
                    <option value="3">ตั้งราคาจัดส่งโซน 3</option>
                    <option value="4">ตั้งราคาจัดส่งโซน 4</option>
                </select>

                <div class="rate" v-if="optionRate !== '0'">
                    <input type="number" v-model="deliveryFee" maxlength="4" placeholder="ราคา" />
                </div>

                <button class="btn btn-sm btn-warning" @click="assignRate()">ตั้งค่าจัดส่ง</button>
            </div>

            <div class="remark mt-2 mb-2"><b-icon icon="info-circle-fill"></b-icon> กดเลือกยี่ห้อยาง หรือขอบล้อ หรือบางส่วน แล้วเลือกกลุ่มด้านบน เพื่อตั้งค่าส่งตามโซน</div>

            <div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                <table v-if="summary">
                    <thead>
                        <tr>
                            <th class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!isSelectAll"
                                        @click="toggleAllSelection(true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="isSelectAll"
                                        @click="toggleAllSelection(false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </th>
                            <th>ยี่ห้อ/รุ่นสินค้า</th>
                            <th>ขนาด</th>
                            <th>ปียาง</th>
                            <th class="text-center">
                                ค่าจัดส่งพื้นที่โซนที่ 1
                                <br />
                                <strong>({{ helper.getCommaNumber(summary.zone1) }} พื้นที่)</strong>
                            </th>
                            <th class="text-center">
                                ค่าจัดส่งพื้นที่โซนที่ 2
                                <br />
                                <strong>({{ helper.getCommaNumber(summary.zone2) }} พื้นที่)</strong>
                            </th>
                            <th class="text-center">
                                ค่าจัดส่งพื้นที่โซนที่ 3
                                <br />
                                <strong>({{ helper.getCommaNumber(summary.zone3) }} พื้นที่)</strong>
                            </th>
                            <th class="text-center">
                                ค่าจัดส่งพื้นที่โซนที่ 4
                                <br />
                                <strong>({{ helper.getCommaNumber(summary.zone4) }} พื้นที่)</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in resultList" :key="index">
                            <td class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!item.isSelect"
                                    @click="toggleSelection(item, true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="item.isSelect"
                                    @click="toggleSelection(item, false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </td>
                            <td>{{ item.brand }}<br />{{ item.model }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.year }}</td>
                            <td class="rate" :class="getStyleRate(item.deliveryFee1)">{{ item.deliveryFee1 === '0' ? 'ไม่จัดส่ง' : item.deliveryFee1 + '.-' }}</td>
                            <td class="rate" :class="getStyleRate(item.deliveryFee2)">{{ item.deliveryFee2 === '0' ? 'ไม่จัดส่ง' : item.deliveryFee2 + '.-' }}</td>
                            <td class="rate" :class="getStyleRate(item.deliveryFee3)">{{ item.deliveryFee3 === '0' ? 'ไม่จัดส่ง' : item.deliveryFee3 + '.-' }}</td>
                            <td class="rate" :class="getStyleRate(item.deliveryFee4)">{{ item.deliveryFee4 === '0' ? 'ไม่จัดส่ง' : item.deliveryFee4 + '.-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import MixinDragScroll from '@/mixins/dragScroll';
import MixinSettingProduct from '@/components/seller/mixins/settingProduct';
import SellerSearchProductSetting from '@/components/seller/shop/SearchProductSetting';

export default {
    mixins: [ MixinDragScroll, MixinSettingProduct ],
    components: {
        SellerSearchProductSetting
    },
	async mounted() {
        this.getDeliveryAreaSummary();
        this.submitSearch();
	},
	data() {
		return {
            helper: Helper,
            optionRate: '',
            deliveryFee: '',
            summary: {
                zone1: 0,
                zone2: 0,
                zone3: 0,
                zone4: 0
            }
		}
	},
	methods: {
        async getDeliveryAreaSummary() {
            let loader = this.$modalLoader.render();
            const result = await SellerService.getDeliveryAreaSummary();
            loader.hide();

            if (result.data) {
                for (const regionKey in result.data.regions) {
                    const region = result.data.regions[regionKey];
                    
                    for (const zoneKey in region) {
                        const zoneValue = parseFloat(region[zoneKey]?.toString().replace(',', ''));

                        this.summary[zoneKey] += zoneValue;
                    }
                }
            }
        },
		async submitSearch(param) {
            this.isSelectAll = false;

			let loader = this.$modalLoader.render();
            const result = await SellerService.getProductForSetting(param);
            loader.hide();

            this.isResultReady = true;
            this.resultList = result.data.map(x => ({ ...x, isSelect: false }));
		},
        getStyleRate(rate) {
            let style = '';

            if (rate !== '0') {
                style = 'text-success';
            }

            return style;
        },
        async assignRate() {
            if ((this.optionRate !== '0' && this.deliveryFee) || this.optionRate === '0') {
                const param = {
                    deliveryFee: this.deliveryFee,
                    deliveryZone: this.optionRate,
                    assignedProducts: this.getSelectedProducts()
                };

                let loader = this.$modalLoader.render();
                await SellerService.assignProductDeliveryRate(param);
                loader.hide();

                // Reload
                this.deliveryFee = '';
                this.submitSearch();
            } else {
                this.$swal(Helper.warningAlert('โปรดตั้งราคาจัดส่ง', 'ยังไม่ได้กรอกค่าราคาจัดส่ง'));
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
@import '@/components/seller/style/Setting.scss';
</style>