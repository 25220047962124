<template>
    <div>
        <seller-search-product-setting @submitSearch="submitSearch($event)"></seller-search-product-setting>

        <template v-if="isResultReady">
            <div class="operation">
                <span>สินค้าที่เลือกไว้ (<span class="text-danger">{{ resultList.filter(x => x.isSelect).length }}</span>)</span>

                <select v-model="minQty">
                    <option v-for="(item, index) in option.qty" :key="index" :value="item.key">
                        {{ item.name }}
                    </option>
                </select>

                <button class="btn btn-sm btn-warning" @click="assignMinQty()">บันทึกจำนวนขั้นต่ำสำหรับจัดส่ง</button>
            </div>

            <div class="remark mt-2 mb-2"><b-icon icon="info-circle-fill"></b-icon> กดเลือกยี่ห้อยาง หรือขอบล้อ หรือบางส่วน แล้วเลือกกลุ่มด้านบน เพื่อบันทึกจำนวนขั้นต่ำ</div>

            <div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                <table>
                    <thead>
                        <tr>
                            <th class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!isSelectAll"
                                        @click="toggleAllSelection(true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="isSelectAll"
                                        @click="toggleAllSelection(false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </th>
                            <th>ยี่ห้อ/รุ่นสินค้า</th>
                            <th>ขนาด</th>
                            <th>ปียาง</th>
                            <th class="text-center">จำนวนขั้นต่ำ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in resultList" :key="index">
                            <td class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!item.isSelect"
                                    @click="toggleSelection(item, true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="item.isSelect"
                                    @click="toggleSelection(item, false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </td>
                            <td>{{ item.brand }}<br />{{ item.model }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.year }}</td>
                            <td class="text-center">{{ item.deliveryMinQty }} เส้น</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import MixinDragScroll from '@/mixins/dragScroll';
import MixinSettingProduct from '@/components/seller/mixins/settingProduct';
import SellerSearchProductSetting from '@/components/seller/shop/SearchProductSetting';

export default {
    mixins: [ MixinDragScroll, MixinSettingProduct ],
    components: {
        SellerSearchProductSetting
    },
	async mounted() {
        this.submitSearch();
	},
	data() {
		return {
			option: {
                qty: Helper.getOptionQty(),
            },
            minQty: '4'
		}
	},
	methods: {
		async submitSearch(param) {
            this.isSelectAll = false;

			let loader = this.$modalLoader.render();
            const result = await SellerService.getProductForSetting(param);
            loader.hide();

            this.isResultReady = true;
            this.resultList = result.data.map(x => ({ ...x, isSelect: false }));
		},
        async assignMinQty() {
            if (this.minQty) {
                const param = {
                    deliveryMinQty: this.minQty,
                    installationMinQty: null,
                    assignedProducts: this.getSelectedProducts()
                };

                let loader = this.$modalLoader.render();
                await SellerService.assignMinQty(param);
                loader.hide();

                // Reload
                this.submitSearch();
            } else {
                this.$swal(Helper.warningAlert('ยังไมไ่ด้เลือกจำนวนขั้นต่ำ', 'โปรดเลือกจำนวนขั้นต่ำสุดที่สามารถสั่งซื้อได้'));
            }
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
@import '@/components/seller/style/Setting.scss';
</style>